import { memo } from "react";
import { moneyFormatter } from "../../Utils";

const ChartLegend = ({ rScale }) => {
  const domain = rScale.domain();
  return (
    <div
      style={{
        position: "absolute",
        right: 4,
        bottom: 4,
        background: "rgba(255,255,255,.85)",
        borderRadius: 4,
      }}
    >
      <div>
        <div style={{ fontWeight: "bold", fontSize: 12, textAlign: "center" }}>
          Node Color
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <svg width={11} height={11} style={{ margin: "5px" }}>
            <pattern
              id="diagonalHatch"
              patternUnits="userSpaceOnUse"
              width="4"
              height="4"
            >
              <path
                d="M-1,1 l2,-2
             M0,4 l4,-4
             M3,5 l2,-2"
                style={{ stroke: "grey", strokeWidth: 1 }}
              />
            </pattern>
            <circle
              fill="url(#diagonalHatch)"
              r={5}
              stroke="black"
              cx={5.5}
              cy={5.5}
              strokeWidth="0.5"
            />
          </svg>
          <div style={{ fontSize: 12 }}>Colored Nodes: High export</div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <svg width={11} height={11} style={{ margin: "5px" }}>
            <circle
              fill="#E9E9E9"
              r={5}
              stroke="black"
              cx={5.5}
              cy={5.5}
              strokeWidth="0.5"
            />
          </svg>
          <div style={{ fontSize: 12 }}>Grey Nodes: Low or no export</div>
        </div>
      </div>
      <br />
      <div style={{ fontWeight: "bold", fontSize: 12, textAlign: "center" }}>
        Node Sizing based on Global Exports
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <svg width={28} height={28} style={{ margin: "5px" }}>
          <circle
            fill="#868686"
            r={5}
            stroke="black"
            cx={14}
            cy={14}
            strokeWidth="0.5"
          />
        </svg>
        <div style={{ fontSize: 12 }}>{moneyFormatter.format(domain[0])}</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <svg width={28} height={28} style={{ margin: "5px" }}>
          <circle
            fill="#868686"
            r={13.5}
            stroke="black"
            cx={14}
            cy={14}
            strokeWidth="0.5"
          />
        </svg>
        <div style={{ fontSize: 12 }}>${moneyFormatter.format(domain[1])}</div>
      </div>
    </div>
  );
};
export default memo(ChartLegend);
