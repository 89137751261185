import { groups } from "d3";
import {
  LocationDetailLevel,
  computeTotalSumByTradeFlow,
  TradeDirection,
} from "../../Utils";
import { getLocationLevelFromStringLocationId } from "../../../sharedUtilities/Utils";
import { LocationLevel } from "../../../graphql/types";
import { groupNames } from "../../../sharedUtilities/Utils";
import { TradeValueScaledByYAxisOption } from "./Chart";

const computeScaledTradeValues = ({
  partnersData,
  deflatorForYearMatchingMetadata,
  tradeFlow,
}: any) => {
  let tradeValues = {
    [TradeValueScaledByYAxisOption.Current]: {},
    [TradeValueScaledByYAxisOption.Constant]: {},
  };

  // Compute CurrentGrossExport trade values
  const sumExportValue = computeTotalSumByTradeFlow({
    data: partnersData,
    tradeFlow,
    tradeDirection: TradeDirection.Exports,
  });
  const sumImportValue = computeTotalSumByTradeFlow({
    data: partnersData,
    tradeFlow,
    tradeDirection: TradeDirection.Imports,
  });

  tradeValues[TradeValueScaledByYAxisOption.Current] = {
    exportValue: sumExportValue,
    importValue: sumImportValue,
  };

  // Compute Constant, inflation-adjusted trade values
  if (deflatorForYearMatchingMetadata) {
    let { deflator } = deflatorForYearMatchingMetadata;
    tradeValues[TradeValueScaledByYAxisOption.Constant] = {
      exportValue:
        sumExportValue !== undefined ? sumExportValue * deflator : undefined,
      importValue:
        sumImportValue !== undefined ? sumImportValue * deflator : undefined,
    };
  }

  return tradeValues;
};

// This version of the function operates on already-transformed data,
// where the computed current gross trade values are stored in an object,
// rather than as flat property-values
const computeScaledTradeValuesHierarchical = ({
  partnersData,
  deflatorForYearMatchingMetadata,
  tradeFlow,
}: any) => {
  let tradeValues = {
    [TradeValueScaledByYAxisOption.Current]: {},
    [TradeValueScaledByYAxisOption.Constant]: {},
  };

  // Compute CurrentGrossExport trade values
  const sumExportValue = computeTotalSumByTradeFlow({
    data: partnersData,
    tradeFlow,
    tradeDirection: TradeDirection.Exports,
    scalingOption: TradeValueScaledByYAxisOption.Current,
  });
  const sumImportValue = computeTotalSumByTradeFlow({
    data: partnersData,
    tradeFlow,
    tradeDirection: TradeDirection.Imports,
    scalingOption: TradeValueScaledByYAxisOption.Current,
  });

  tradeValues[TradeValueScaledByYAxisOption.Current] = {
    exportValue: sumExportValue,
    importValue: sumImportValue,
  };

  // Compute Constant, inflation-adjusted trade values
  if (deflatorForYearMatchingMetadata) {
    let { deflator } = deflatorForYearMatchingMetadata;
    tradeValues[TradeValueScaledByYAxisOption.Constant] = {
      exportValue:
        sumExportValue !== undefined ? sumExportValue * deflator : undefined,
      importValue:
        sumImportValue !== undefined ? sumImportValue * deflator : undefined,
    };
  }

  return tradeValues;
};

const makeCompleteObservationsLocations = ({
  inputData,
  yearRangeForProductClass,
}: any) => {
  const { startYear, endYear } = yearRangeForProductClass;

  let completeObservationsLocations: any[] = [];

  groups(
    inputData,
    (d: any) => d.partnerCountryId,
    (d: any) => d.year,
  ).forEach((d: any) => {
    const yearsData = d[1];

    let prototypeDatumForYear: any;

    yearsData.forEach((yearDatum: any, i: number) => {
      const yearsDataForPartner = yearDatum[1];
      completeObservationsLocations.push(...yearsDataForPartner);
      if (i == 0) {
        prototypeDatumForYear = Object.assign({}, yearsDataForPartner[0]);
      }
    });

    for (let checkYear = startYear; checkYear <= endYear; checkYear++) {
      let findMatchingYear = yearsData.find(
        (yearDatum: any) => yearDatum[0] === checkYear,
      );
      if (!findMatchingYear) {
        let completeObservationForYear = {
          ...prototypeDatumForYear,
          year: checkYear,
          exportValue: 0,
          importValue: 0,
        };

        completeObservationsLocations.push(completeObservationForYear);
      }
    }
  });

  return completeObservationsLocations;
};

const transformLocations = ({
  untransformedData,
  location,
  currentLocationDetailLevel,
  regions,
  subregions,
  countries,
  deflators,
  yearRangeForProductClass,
  tradeFlow,
}: any) => {
  if (untransformedData.length == 0) {
    return undefined;
  }

  let locationLevel = getLocationLevelFromStringLocationId(location);

  const data = makeCompleteObservationsLocations({
    inputData: untransformedData,
    yearRangeForProductClass,
  });

  if (location) {
    if (locationLevel === LocationLevel.Country) {
      let flatGroupedByPartnerAndYear: any[] = [];

      const groupedByPartnerAndYear = groups(
        data,
        (d: any) => d.partnerCountryId,
        (d: any) => d.year,
      ).map((d: any) => {
        const partnerCountryId = d[0];
        const yearsData = d[1];

        // Get location metadata for region and subregion levels
        let findMatchingMetadata = regions.find((region: any) =>
          region.members.includes(partnerCountryId),
        );
        let topLevelParent: any = undefined;
        let nameEn: string | undefined = undefined;
        let hybrid_level_1: any;
        let hybrid_level_2: any;
        if (findMatchingMetadata) {
          topLevelParent = findMatchingMetadata.groupId;
          hybrid_level_1 = {
            id: findMatchingMetadata.groupId,
            nameEn: findMatchingMetadata.groupName,
          };
        } else {
          topLevelParent = "undisclosed";
          hybrid_level_1 = { id: "undisclosed", nameEn: "undisclosed" };
        }

        let findMatchingSubregion = subregions.find((subregion: any) =>
          subregion.members.includes(partnerCountryId),
        );
        if (findMatchingSubregion) {
          hybrid_level_2 = {
            id: findMatchingSubregion.groupId,
            nameEn: findMatchingSubregion.groupName,
          };
        } else {
          hybrid_level_2 = { id: "undisclosed", nameEn: "undisclosed" };
        }

        let findMatchingCountryMetadata = countries.find(
          (country: any) => country.countryId === partnerCountryId,
        );
        if (findMatchingCountryMetadata) {
          nameEn = findMatchingCountryMetadata.nameShortEn;
        } else {
          nameEn = "undisclosed";
        }

        const summarizedByYear = yearsData.map((d: any) => {
          const year = d[0];
          const partnersData = d[1];
          const partnerLevel = partnersData[0].partnerLevel;

          let deflatorForYearMatchingMetadata = deflators.find(
            (deflatorDatum: any) => deflatorDatum.year == year,
          );
          let tradeValues = computeScaledTradeValues({
            partnersData,
            deflatorForYearMatchingMetadata,
            tradeFlow,
          });

          return {
            countryId: location,
            locationLevel: "country",
            partnerId: partnerCountryId,
            partnerLevel,
            year,
            partnersData,
            // Matching metadata
            topLevelParent: topLevelParent,
            hybrid_level_1,
            hybrid_level_2,
            nameEn,
            ...tradeValues,
          };
        });

        flatGroupedByPartnerAndYear.push(...summarizedByYear);
      });

      let groupedByLocationDetailLevel: any[] = [];
      if (currentLocationDetailLevel === LocationDetailLevel.country) {
        groupedByLocationDetailLevel = flatGroupedByPartnerAndYear;
      } else {
        groups(flatGroupedByPartnerAndYear, (d: any) => {
          if (currentLocationDetailLevel === LocationDetailLevel.region) {
            return d.hybrid_level_1.id;
          } else if (
            currentLocationDetailLevel === LocationDetailLevel.subregion
          ) {
            return d.hybrid_level_2.id;
          }
        }).map((d: any) => {
          const partnerId = d[0];
          const yearsDataForPartner = d[1];
          let nameEn: string | undefined;
          let topLevelParent: string | undefined =
            yearsDataForPartner[0].hybrid_level_1.id;
          if (currentLocationDetailLevel === LocationDetailLevel.region) {
            nameEn = yearsDataForPartner[0].hybrid_level_1.nameEn;
          } else if (
            currentLocationDetailLevel === LocationDetailLevel.subregion
          ) {
            nameEn = yearsDataForPartner[0].hybrid_level_2.nameEn;
          }

          groups(yearsDataForPartner, (d: any) => d.year).forEach(
            (yearDatum: any) => {
              let year = yearDatum[0];
              let countriesForYear = yearDatum[1];

              let deflatorForYearMatchingMetadata = deflators.find(
                (deflatorDatum: any) => deflatorDatum.year == year,
              );
              let tradeValues = computeScaledTradeValuesHierarchical({
                partnersData: countriesForYear,
                deflatorForYearMatchingMetadata,
                tradeFlow,
              });

              groupedByLocationDetailLevel.push({
                countryId: location,
                partnerId,
                year: year,
                nameEn,
                topLevelParent,
                ...tradeValues,
              });
            },
          );
        });
      }

      // Sort locations by region
      groupedByLocationDetailLevel.sort((partnerA: any, partnerB: any) => {
        let partnerAIndex = [...groupNames.keys()].indexOf(
          partnerA.topLevelParent,
        );
        let partnerBIndex = [...groupNames.keys()].indexOf(
          partnerB.topLevelParent,
        );
        return partnerAIndex - partnerBIndex;
      });

      return groupedByLocationDetailLevel;
    } else if (locationLevel === LocationLevel.Group) {
      let flatGroupedByPartnerAndYear: any[] = [];

      const groupedFilteredData = groups(
        data,
        (d: any) => d.partnerCountryId,
        (d: any) => d.year,
      ).map((d: any) => {
        const partnerCountryId = d[0];
        const yearsData = d[1];
        let findMatchingMetadata = regions.find((region: any) =>
          region.members.includes(partnerCountryId),
        );
        let topLevelParent: any = undefined;
        let nameEn: string | undefined = undefined;
        let hybrid_level_1: any;
        let hybrid_level_2: any;
        if (findMatchingMetadata) {
          topLevelParent = findMatchingMetadata.groupId;
          hybrid_level_1 = {
            id: findMatchingMetadata.groupId,
            nameEn: findMatchingMetadata.groupName,
          };
        } else {
          topLevelParent = "undisclosed";
          hybrid_level_1 = { id: "undisclosed", nameEn: "undisclosed" };
        }

        let findMatchingSubregion = subregions.find((subregion: any) =>
          subregion.members.includes(partnerCountryId),
        );
        if (findMatchingSubregion) {
          hybrid_level_2 = {
            id: findMatchingSubregion.groupId,
            nameEn: findMatchingSubregion.groupName,
          };
        } else {
          hybrid_level_2 = { id: "undisclosed", nameEn: "undisclosed" };
        }

        let findMatchingCountryMetadata = countries.find(
          (country: any) => country.countryId === partnerCountryId,
        );
        if (findMatchingCountryMetadata) {
          nameEn = findMatchingCountryMetadata.nameShortEn;
        } else {
          nameEn = "undisclosed";
        }

        const summarizedByYear = yearsData.map((d: any) => {
          const year = d[0];
          const partnersData = d[1];
          const partnerLevel = partnersData[0].partnerLevel;

          let deflatorForYearMatchingMetadata = deflators.find(
            (deflatorDatum: any) => deflatorDatum.year == year,
          );
          let tradeValues = computeScaledTradeValues({
            partnersData,
            deflatorForYearMatchingMetadata,
            tradeFlow,
          });

          return {
            groupId: location,
            locationLevel: "country",
            partnerId: partnerCountryId,
            partnerLevel,
            year,
            partnersData,
            // Matching metadata
            topLevelParent: topLevelParent,
            hybrid_level_1,
            hybrid_level_2,
            nameEn,
            ...tradeValues,
          };
        });

        flatGroupedByPartnerAndYear.push(...summarizedByYear);
      });

      let groupedByLocationDetailLevel: any[] = [];
      if (currentLocationDetailLevel === LocationDetailLevel.country) {
        groupedByLocationDetailLevel = flatGroupedByPartnerAndYear;
      } else {
        groups(flatGroupedByPartnerAndYear, (d: any) => {
          if (currentLocationDetailLevel === LocationDetailLevel.region) {
            return d.hybrid_level_1.id;
          } else if (
            currentLocationDetailLevel === LocationDetailLevel.subregion
          ) {
            return d.hybrid_level_2.id;
          }
        }).map((d: any) => {
          const partnerId = d[0];
          const yearsDataForPartner = d[1];

          groups(yearsDataForPartner, (d: any) => d.year).forEach(
            (yearDatum: any) => {
              let year = yearDatum[0];
              let countriesForYear = yearDatum[1];

              let nameEn: string | undefined;
              let topLevelParent: string | undefined =
                countriesForYear[0].hybrid_level_1.id;
              if (currentLocationDetailLevel === LocationDetailLevel.region) {
                nameEn = countriesForYear[0].hybrid_level_1.nameEn;
              } else if (
                currentLocationDetailLevel === LocationDetailLevel.subregion
              ) {
                nameEn = countriesForYear[0].hybrid_level_2.nameEn;
              }

              let deflatorForYearMatchingMetadata = deflators.find(
                (deflatorDatum: any) => deflatorDatum.year == year,
              );
              let tradeValues = computeScaledTradeValuesHierarchical({
                partnersData: countriesForYear,
                deflatorForYearMatchingMetadata,
                tradeFlow,
              });

              groupedByLocationDetailLevel.push({
                groupId: location,
                partnerId,
                year: year,
                nameEn,
                topLevelParent,

                ...tradeValues,
              });
            },
          );
        });
      }

      // Sort locations by region
      groupedByLocationDetailLevel.sort((partnerA: any, partnerB: any) => {
        let partnerAIndex = [...groupNames.keys()].indexOf(
          partnerA.topLevelParent,
        );
        let partnerBIndex = [...groupNames.keys()].indexOf(
          partnerB.topLevelParent,
        );
        return partnerAIndex - partnerBIndex;
      });

      return groupedByLocationDetailLevel;
    }
  }
};

export default transformLocations;
