import styled from "@emotion/styled";
import { LocationMetadatumLevel } from "../../../graphql/types";
import MainDropdownCombobox, {
  DropdownRole,
} from "../../mainDropdownPrimitive";
import LocationDropdownItem from "./LocationDropdownItem";
import useFetchMetadata, {
  MetadataFetchType,
  MetadataFetchStatus,
} from "../../../sharedUtilities/useFetchMetadata";
import { memo } from "react";
import {
  groupAndOrderLocationOptions,
  locationSearchStringFunction,
  locationFilterSearchResults,
  updateVisibilityStatus,
} from "./Utils";

import { usePageQueryParams } from "../../../visualization/defaultSettings";
import { UIView } from "../../../visualization/Utils";
import { worldGroupDatum } from "../../../graphql/queries/getLocationsMetadata";

const DropdownMenu = styled.select`
  width: 100%;
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 1vh;
  right: 1vw;
  cursor: pointer;
  color: #aaa;
  font-size: 1.6rem;
  font-weight: 300;
  padding: 12px;
`;

interface LocationDropdownInput {
  locationSelectionRole: DropdownRole | undefined;
}

export const useChangeImporter = () => {
  const [{ exporter, view }, setQuery] = usePageQueryParams();
  return (id) => {
    let newImporter = id;
    if (
      view === UIView.Markets &&
      newImporter === worldGroupDatum.groupId &&
      exporter === worldGroupDatum.groupId
    ) {
      setQuery({
        importer: newImporter,
        exporter: "",
      });
    } else if (
      view === UIView.Markets &&
      newImporter !== worldGroupDatum.groupId &&
      exporter === ""
    ) {
      setQuery({
        importer: newImporter,
        exporter: worldGroupDatum.groupId,
      });
    } else {
      setQuery({
        importer: newImporter,
        exporter: exporter ? exporter : worldGroupDatum.groupId,
      });
    }
  };
};

export const useChangeExporter = () => {
  const [{ importer, view }, setQuery] = usePageQueryParams();
  return (id) => {
    let newExporter = id;
    if (
      view === UIView.Markets &&
      newExporter === worldGroupDatum.groupId &&
      importer === worldGroupDatum.groupId
    ) {
      setQuery({
        exporter: newExporter,
        importer: "",
      });
    } else if (
      view === UIView.Markets &&
      newExporter !== worldGroupDatum.groupId &&
      importer === ""
    ) {
      setQuery({
        exporter: newExporter,
        importer: worldGroupDatum.groupId,
      });
    } else {
      setQuery({
        exporter: newExporter,
        importer: importer ? importer : worldGroupDatum.groupId,
      });
    }
  };
};

const LocationDropdown = ({ locationSelectionRole }: LocationDropdownInput) => {
  const [{ importer: currentImporter, exporter: currentExporter }] =
    usePageQueryParams();
  const changeExporter = useChangeExporter();
  const changeImporter = useChangeImporter();
  const { metadataStatus, metadata, error } = useFetchMetadata({
    metadataFetchType: MetadataFetchType.Location,
  });

  if (metadataStatus === MetadataFetchStatus.Success) {
    const { countries, regions, subregions } = metadata;

    const grouped = groupAndOrderLocationOptions({
      countries,
      regions,
      subregions,
    });

    const itemsWithVisibilityStatusInitial = grouped.map((item) => {
      item.isExpanded = false;
      if (
        item.locationLevel === LocationMetadatumLevel.region ||
        item.locationLevel === LocationMetadatumLevel.world
      ) {
        item.isVisible = true;
      } else {
        item.isVisible = false;
      }
      return item;
    });

    let matchSelectedItem: any = undefined;
    let valueToMatch: any;
    if (locationSelectionRole) {
      if (locationSelectionRole === DropdownRole.LocationExporter) {
        valueToMatch = currentExporter;
      } else if (locationSelectionRole === DropdownRole.LocationImporter) {
        valueToMatch = currentImporter;
      }
    } else {
      valueToMatch = currentExporter;
    }
    if (valueToMatch) {
      matchSelectedItem = itemsWithVisibilityStatusInitial.find(
        (item) => item.id === valueToMatch,
      );
      if (matchSelectedItem) {
        itemsWithVisibilityStatusInitial.forEach((item) => {
          // Countries in the same subregion as the selected country should be visible
          if (
            item.locationLevel === LocationMetadatumLevel.country &&
            item.hybrid_level_2 === matchSelectedItem.hybrid_level_2
          ) {
            item.isVisible = true;
            item.isVisibleChange = false;
            // the selected country's subregion should be expanded
          } else if (
            item.locationLevel === LocationMetadatumLevel.subregion &&
            item.id === matchSelectedItem.hybrid_level_2
          ) {
            item.isVisible = true;
            item.isVisibleChange = false;
            item.isExpanded = true;
            // other subregions in the selected country's region should be visible but not expanded
          } else if (
            item.locationLevel === LocationMetadatumLevel.subregion &&
            item.hybrid_level_1 === matchSelectedItem.hybrid_level_1
          ) {
            item.isVisible = true;
            item.isVisibleChange = false;
            item.isExpanded = false;
            // the region of the select country should be visible and expanded
          } else if (
            item.locationLevel === LocationMetadatumLevel.region &&
            item.id === matchSelectedItem.hybrid_level_1
          ) {
            item.isVisible = true;
            item.isVisibleChange = false;
            item.isExpanded = true;
          }
        });
      }
    }

    let dispatchAction;
    if (locationSelectionRole) {
      if (locationSelectionRole === DropdownRole.LocationImporter) {
        dispatchAction = ({ id }: { id: string }) => {
          changeImporter(id);
        };
      } else {
        dispatchAction = ({ id }: { id: string }) => {
          changeExporter(id);
        };
      }
    }

    return (
      <>
        <MainDropdownCombobox
          items={itemsWithVisibilityStatusInitial}
          hashFunction={(item: any) => item.name_short_en}
          searchStringFunction={locationSearchStringFunction}
          placeholder="Please select a location"
          ItemRenderComponent={LocationDropdownItem}
          onChangeEvent={dispatchAction}
          selectedValueFromStore={matchSelectedItem}
          updateVisibilityFunction={updateVisibilityStatus}
          filterFunction={locationFilterSearchResults}
          dropdownRole={locationSelectionRole}
        />
      </>
    );
  } else {
    if (error) console.log(error);
    return <DropdownMenu />;
  }
};

export default memo(LocationDropdown);
