import styled from "@emotion/styled";
import { useRef, useState } from "react";
import { BaseMenuItem } from "./styles";

const SettingsMenuItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-bottom: 2px;
`;

const LabelContainer = styled.div`
  width: calc(100% - 1px);
  width: 100%;
  padding-left: 10px;
  overflow: hidden;
  ppadding: auto 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const NotAvailableTooltip = styled.div`
  position: absolute;
  top: -90%;
  left: 0px;
  margin-bottom: 100px;
  width: 100%;
  font-size: 0.7rem;
  background-color: #ffffff;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;
  z-index: 1000;
  box-shadow: 0px 2px 15px #ccc;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: calc(50% - 10px);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #ffffff;
  }
`;

interface SettingsDropdownItemInput {
  item: any;
  hashFunction: any;
  getItemProps: any;
  mappedItemIndex?: number;
  index: number;
  highlightedIndex: number;
  displayItems?: any;
  setDisplayItems?: any;
  updateVisibilityFunction?: any;
  selectedItem: any;
}

const SettingsDropdownItem = ({
  item,
  hashFunction,
  selectedItem,
  index,
  highlightedIndex,
  getItemProps,
}: SettingsDropdownItemInput) => {
  const dropdownItemRef = useRef(null);

  return (
    <BaseMenuItem
      ref={dropdownItemRef}
      className={
        index === highlightedIndex ||
        (selectedItem && item.value === selectedItem.value)
          ? "menu-item--highlighted"
          : ""
      }
      key={`item_${item.value}`}
      {...getItemProps({ item, index })}
    >
      <SettingsMenuItem>
        <LabelContainer>{hashFunction(item)}</LabelContainer>
      </SettingsMenuItem>
    </BaseMenuItem>
  );
};

export const DisabledSettingsDropdownItem = ({
  item,
  hashFunction,
  selectedItem,
  index,
  highlightedIndex,
  getItemProps,
}: SettingsDropdownItemInput) => {
  const dropdownItemRef = useRef(null);

  const [isHovered, setIsHovered] = useState<boolean>(false);

  let tooltipNotification;
  if (isHovered) {
    tooltipNotification = (
      <NotAvailableTooltip>This feature is not available</NotAvailableTooltip>
    );
  } else {
    tooltipNotification = null;
  }

  return (
    <BaseMenuItem
      ref={dropdownItemRef}
      className={"disabled"}
      key={`item_${item.value}`}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      <SettingsMenuItem>
        <LabelContainer>{hashFunction(item)}</LabelContainer>
      </SettingsMenuItem>
      {tooltipNotification}
    </BaseMenuItem>
  );
};

export default SettingsDropdownItem;
